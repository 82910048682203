





































































































































































import { Vue, Component, Emit, Prop, VModel } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { LoaderComponent } from 'vue-loading-overlay';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { sortBy } from 'lodash';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { FormHelper } from '@/utils/helpers/form-helper';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import ContactService from '@/services/crm/contact.service';
import ActivityService from '@/services/crm/activity.service';
import Field from '@/components/field.vue';
import ContactModel from '@/models/crm/contact.model';
import ContactTypeModel from '@/models/crm/contact-type.model';
import ClientModel from '@/models/crm/client.model';
import DatePickerField from '@/components/date-picker-field.vue';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import { Translations } from '@/utils/phone-field-plugin/translations';
import CardDataList from '../card-data-list.vue';
import CrmExistingContactsView from './existing-contacts-view.vue';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import { ObjectHelper } from '@/utils/helpers/object-helper';
import TimePickerField from '@/components/time-picker-field.vue';
import OriginModel from '@/models/crm/origin.model';

@Component({
  components: {
    Field,
    CardDataList,
    DatePickerField,
    VuePhoneNumberInput,
    CrmExistingContactsView,
    TimePickerField,
  },
})
export default class CrmContactForm extends Vue {
  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @Prop()
  id!: number;

  @Prop({ required: true })
  client!: ClientModel;

  @Prop({ default: false })
  flagEnviaContatoErp!: boolean;

  @Prop()
  readonly titleGeneral!: string;

  @Prop()
  readonly titlePreference!: string;

  @Prop()
  readonly iconPreference!: string;

  @Prop()
  readonly iconGeneral!: string;

  formIsValid = true;

  model: ContactModel = plainToClass(ContactModel, {
    id: null,
    idProspect: null,
    nome: null,
    tiposContato: null,
    email: null,
    telefone: null,
    dataAniversario: null,
    flagAtivo: 1,
    melhorHoraInicio: null,
    melhorHoraFim: null,
    canaisContato: null,
  });

  modelLoad!: unknown;

  rules = {
    nome: [ValidationRules.required],
    email: [ValidationRules.email],
    telefone: [ValidationRules.phone],
    whatsapp: [ValidationRules.phone],
    tiposContato: [ValidationRules.required],
  };

  dialogConfig: IKeyValue<IDialogConfig> = {
    existingContacts: {
      show: false,
    },
    existingWhatsappContacts: {
      show: false,
    },
  };

  existingContacts: ContactModel[] = [];

  contactTypeOptions: ContactTypeModel[] = [];

  telephoneMaskActive = false;

  translations: unknown = Translations.getTranslations();

  translationsWhatsapp: unknown = Translations.getTranslationsWhatsApp();

  preferredCountries: string[] = ['BR'];

  isWhatsAppValid = true;

  isTelephoneValid = true;

  whatsAppCountryCallingCode!: string;

  @VModel()
  hasExistingContacts!: boolean;

  whatsappChanged = false;

  telephoneChanged = false;

  whatsapp = '';

  telephone = '';

  whatsappAcceptedDuplicate = '';

  clickedFromSave = false;

  isSaving = false;

  channelOptions: OriginModel[] = [];

  async mounted(): Promise<void> {
    const isProspect = this.client.type === ClientTypeEnum.Prospect;
    this.model.cnpj = this.client.cnpjCpf;
    this.model.idProspect = isProspect ? parseInt(this.client.codCliente, 10) : undefined;
    const loader = this.setBusyLoader();
    try {
      const getContactTypesTask = this.loadContactTypes();
      const getContactTask = this.loadModel();
      const getContactChannels = this.loadOrigins();
      await Promise.all([getContactTypesTask, getContactTask, getContactChannels]);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  async onVerifyWhatsappContact(isSavingValidation = false): Promise<void> {
    this.hasExistingContacts = false;
    if (this.isWhatsappBlank) {
      this.whatsappChanged = false;
      return;
    }

    if (this.model.whatsapp === this.whatsapp
        || `+${this.whatsAppCountryCallingCode} ${this.whatsapp}` === this.model.whatsapp
        || this.whatsapp === this.whatsappAcceptedDuplicate) {
      this.whatsappChanged = false;
      this.isWhatsAppValid = true;
      if (isSavingValidation && this.clickedFromSave) {
        this.onSave();
      }
      return;
    }

    if (this.whatsAppCountryCallingCode && !this.whatsapp.startsWith(this.whatsAppCountryCallingCode)) {
      this.whatsapp = `+${this.whatsAppCountryCallingCode} ${this.whatsapp}`;
    } else if (this.whatsapp) {
      this.whatsapp = `+${this.whatsapp}`;
    }

    let clientId!: string | null;
    if (this.client.type === ClientTypeEnum.Prospect && this.client.prospectId) {
      clientId = this.client?.prospectId?.toString();
    } else {
      clientId = this.client.cnpjCpf;
    }

    try {
      this.existingContacts = await this.contactService.verifyWaIdContacts(
        this.model.id,
        this.whatsapp,
        this.client.type,
        clientId,
      );

      if (this.existingContacts.length !== 0) {
        this.toggleDialogExistingWhatsappContacts(isSavingValidation);
        if (isSavingValidation) {
          this.isSaving = false;
        }
      } else {
        this.whatsappChanged = false;
        this.isWhatsAppValid = true;
        if (this.clickedFromSave) {
          this.clickedFromSave = false;
          this.onSave();
        }
      }
    } catch (err) {
      this.isWhatsAppValid = false;
      this.whatsapp = '';
      this.$notify.error(err && (err as Error).message);
    }
  }

  async onVerifyContact(isSavingValidation = false): Promise<void> {
    this.hasExistingContacts = false;
    if (this.isTelephoneBlank) {
      this.telephoneChanged = false;
      this.isTelephoneValid = true;
      return;
    }

    if (this.telephone === this.model.telefone) {
      this.telephoneChanged = false;
      this.isTelephoneValid = true;
      return;
    }

    let clientId!: string | null;
    if (this.client.type === ClientTypeEnum.Prospect && this.client.prospectId) {
      clientId = this.client?.prospectId?.toString();
    } else {
      clientId = this.client.cnpjCpf;
    }

    try {
      this.existingContacts = await this.contactService.verifyWaIdContacts(
        this.model.id,
        this.telephone,
        this.client.type,
        clientId,
        true,
        true,
      );

      if (this.existingContacts.length !== 0) {
        this.toggleDialogExistingContacts(isSavingValidation);
        if (isSavingValidation) {
          this.isSaving = false;
        }
      } else {
        this.telephoneChanged = false;
        this.isTelephoneValid = true;
        if (this.clickedFromSave) {
          this.clickedFromSave = false;
          this.onSave();
        }
      }
    } catch (err) {
      this.isTelephoneValid = false;
      this.telephone = '';
      this.$notify.error(err && (err as Error).message);
    }
  }

  onCancelSave(isForWhatsappField = true): void {
    if (isForWhatsappField) {
      this.whatsapp = '';
      this.onChangeWhatsApp({ isValid: false });
      this.toggleDialogExistingWhatsappContacts();
    } else {
      this.telephone = '';
      this.toggleDialogExistingContacts();
    }
  }

  toggleDialogExistingContacts(isSavingValidation = false): void {
    if (!this.isSaving || (this.isSaving && isSavingValidation)) {
      this.dialogConfig.existingContacts.show = !this.dialogConfig.existingContacts.show;
      this.hasExistingContacts = !this.hasExistingContacts;
    }
  }

  toggleDialogExistingWhatsappContacts(isSavingValidation = false): void {
    if (!this.isSaving || (this.isSaving && isSavingValidation)) {
      this.dialogConfig.existingWhatsappContacts.show = !this.dialogConfig.existingWhatsappContacts.show;
      this.hasExistingContacts = !this.hasExistingContacts;
    }
  }

  async onSave(): Promise<void> {
    this.isSaving = true;
    if (this.whatsappChanged) {
      if (this.whatsapp === this.whatsappAcceptedDuplicate && this.whatsappAcceptedDuplicate !== '') {
        this.whatsappChanged = false;
        this.onSave();
      } else {
        this.clickedFromSave = true;
        await this.onVerifyWhatsappContact(true);
      }
    } else if (this.telephoneChanged) {
      this.clickedFromSave = true;
      await this.onVerifyContact(true);
    } else if (FormHelper.validate(this.$refs.form as Vue) && !this.whatsappChanged && !this.telephoneChanged) {
      if (!this.isWhatsAppValid && this.whatsAppCountryCallingCode === undefined) {
        this.isWhatsAppValid = true;
      }
      if (this.isWhatsappBlank && this.isTelephoneBlank) {
        this.$notify.error(`${this.$t('global.validations.phone', { fieldName: 'Telefone ou Whatsapp' })}`);
        return;
      }

      if (this.whatsAppCountryCallingCode !== undefined && (this.whatsapp == null)) {
        this.whatsAppCountryCallingCode = '';
      }

      if (!this.isWhatsAppValid || !this.isTelephoneValid) {
        let fieldName = 'Whatsapp';
        if (!this.isTelephoneValid) {
          fieldName = 'Telefone';
        }
        this.$notify.error(`${this.$t('global.validations.phone', { fieldName })}`);
        return;
      }

      this.model.cnpj = this.client.cnpjCpf;

      if (this.whatsAppCountryCallingCode && !this.whatsapp.startsWith(this.whatsAppCountryCallingCode)) {
        this.model.whatsapp = `+${this.whatsAppCountryCallingCode} ${this.whatsapp}`;
      } else if (this.model.whatsapp) {
        this.model.whatsapp = `+${this.whatsapp}`;
      }
      if (this.model.whatsapp === '+null') {
        this.model.whatsapp = '';
      }
      this.model.telefone = this.telephone;

      const loader = this.$loading.show();
      try {
        this.model.cnpj = this.client.cnpjCpf;
        if (this.client.type === ClientTypeEnum.Prospect) {
          this.model.cnpj = '';
        }

        if (this.model.id) {
          await this.contactService.update(this.model);
        } else {
          await this.contactService.create(this.model);
        }

        this.$notify.success(this.$t('crm.contactForm.successfullySave'));
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }

      this.$emit('complete', this.model);
    }
  }

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  get showSync(): boolean {
    return !!this.model.id && this.flagEnviaContatoErp;
  }

  get disableSync(): boolean {
    return JSON.stringify(this.model) !== JSON.stringify(plainToClass(ContactModel, this.modelLoad));
  }

  get saveButtonText(): string {
    if (this.flagEnviaContatoErp) return this.$t('global.saveAndSync').toString();
    return this.$t('global.save').toString();
  }

  get telephoneMask(): string | boolean {
    const parsedNumber = (this.telephone || '').replace(/\D/g, '');
    if (parsedNumber.length > 10) {
      return '(##) #####-####';
    }
    if (parsedNumber !== '') {
      return '(##) ####-####';
    }
    return '';
  }

  get isTelephoneBlank(): boolean {
    return this.telephone === undefined || this.telephone === '' || this.telephone === null;
  }

  get isWhatsappBlank(): boolean {
    return this.whatsapp === undefined || this.whatsapp === '' || this.whatsapp === null;
  }

  private async loadOrigins(): Promise<void> {
    this.channelOptions = sortBy(await this.activityService.getOrigins(), 'descricao');
  }

  private async loadModel(): Promise<void> {
    if (!this.id) {
      return;
    }

    const result = await this.contactService.getContact(this.id);
    const mappedObject = ObjectHelper.mapObjectValues<ContactModel>(result, {
      ignore: ['flagMethod', 'inclusaoUsuarioId', 'inclusaoData', 'alteracaoUsuarioId', 'alteracaoData', 'tipo'],
    });

    this.modelLoad = mappedObject;
    this.model = plainToClass(ContactModel, mappedObject);
    this.whatsapp = this.model.whatsapp;
    this.telephone = this.model.telefone;
    setTimeout(() => {
      this.telephoneMaskActive = true;
    }, 50);
  }

  onChangeWhatsApp(e): void {
    this.whatsAppCountryCallingCode = e.countryCallingCode;
    this.isWhatsAppValid = e.isValid || this.whatsAppCountryCallingCode === undefined;
    if (!this.whatsappChanged) {
      this.whatsappChanged = true;
    }
  }

  onChangeTelephone(): void {
    if (!this.telephoneChanged) {
      this.telephoneChanged = true;
    }
  }

  private async loadContactTypes(): Promise<void> {
    this.contactTypeOptions = await this.contactService.getContactTypes();
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.contactFormContainer,
      canCancel: false,
    });
  }

  public onChangeTipoContato(event): void {
    if (event instanceof Array && event.length === 0) {
      this.model.tiposContato = null;
    }
  }
}
